<template>
  <div class="">
    <PDialog
      class="stepper-dialog"
      :header="title"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: '60vw' }"
      :contentStyle="{
        overflow: 'auto',
      }"
    >
      <template #header>
        <div class="card px-0 border-0 w-100">
          <div>
            <h4 class="d-flex justify-content-between">
              {{ title }}
              <PButton
                @click="cancel()"
                icon="pi pi-times"
                class="p-button-rounded p-button-outlined p-button-danger"
              />
            </h4>
            <PDivider />
          </div>
        </div>
      </template>
      <template>
        <div class="bg-white shadow-light rounded-3 bg-r p-3">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          
            <form ref="form0" method="post" @submit.prevent="handleSubmit()">
              <div class="row">
                <div class="col-sm-12 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        v-uppercase
                        id="libelle"
                        class="form-control"
                        name="libelle"
                        v-model="editableItem.libelle"
                      />
                      <label for="libelle">Libellé de la ligne budgétaire</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Date de début</div>
                    <p-calendar
                      class="col-12 md:col-12"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      name="dateDebut"
                      :manualInput="false"
                      v-model="editableItem.dateDebut"
                      :showWeek="true"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div>Date de fin</div>
                    <p-calendar
                      class="col-12 md:col-12"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      name="dateFin"
                      :manualInput="false"
                      v-model="editableItem.dateFin"
                      :showWeek="true"
                    />
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="col-sm-12 field mt-3">
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <textarea
                        type="text"
                        rows="10"
                        style="height: 120px"
                        id="description"
                        class="form-control"
                        name="description"
                        v-model="editableItem.description"
                      />
                      <label for="description">Description</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </template>
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  data() {
    return {
      display: false,
      editableItem: {
        libelle: null,
        description: null,
        dateDebut: null,
        dateFin: null,
      },
    };
  },
  created() {},
  watch: {
    item() {
      if (this.item && this.item.datePrevue instanceof String) {
        this.item.datePrevue = new Date(this.item.datePrevue);
      }
      this.editableItem = { ...this.item };
    },
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
    resetContent() {},
    setEditableItem(item) {
      this.editableItem = item;
    },
    show() {
      this.resetContent();
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async isCompleted() {
      const valid = await this.$refs["observer"].validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("saved", this.editableItem);
      this.hide();
    },
  },
};
</script>

<style>
.field-checkbox,
.field-radiobutton {
  display: flex;
  justify-items: center;
}
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #e1e4e1;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}
</style>
