/* eslint-disable vue/no-unused-components */
<template>
  <div :class="{ 'container-fluid': !hideHeader || noPadding }">
    <FichePosteProgrammeFormDialog
      ref="ficheDialog"
      :title="'Edition de la fiche post-programme'"
      @saved="handleFicheSave"
    />
    <div class="statistic-box">
      <div class="row gap-4">
        <div
          class="col p-2 rounded border border-warning"
          v-for="(st, i) in stats"
          :key="i"
        >
          <div>{{ st.libelle }}</div>
          <h2>{{ st.totalCount }}</h2>
          <div class="d-flex">
            <div>
              <span>Sexe M:</span>
              <h6>{{ st.sexeMCount }}</h6>
            </div>
            <PDivider layout="vertical" />
            <div>
              <span>Sexe F:</span>
              <h6>{{ st.sexeFCount }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container- mt-1">
      <template v-if="!hideHeader">
        <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
        <div class="bg-white p-2 rounded-4">
          <form class="bg-" method="post" accept-charset="utf-8" action="">
            <div class="row py-0 p-fluid grid formgrid">
              <div class="col field">
                <form class="d-flex" @submit.prevent="submitQuery">
                  <div class="input-group me-4">
                    <div class="p-inputgroup">
                      <PInputText
                        type="text"
                        v-model="mxSearch"
                        :placeholder="'Rechercher par'"
                      />
                      <!-- <p-multi-select v-model="mxSearchFields" :options="searchFieldsOptions.filter(el => el.visible)" optionLabel="label" optionValue="value" :editable="true"/> -->
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-6 col-sm-12 d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <p-dropdown
                      class="flex-grow-auto me-2"
                      v-model="statutEnquete"
                      :options="statutEnqueteOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Statut Enquête'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="demandeurSexe"
                      :options="demandeurSexeOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Sexe DE'"
                      display="chip"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-auto col-sm-12 d-flex">
                <div class="d-grid gap-2">
                  <a
                    href="#"
                    @click.prevent="submitQuery"
                    class="style_btn btn btn-primary"
                    id="button-query"
                    ><i class="bi bi-search"></i
                  ></a>
                </div>
              </div>
              <div class="col-auto">
                <button
                  type="button"
                  @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                  class="style_btn btn btn-warning"
                  id="button-query"
                >
                  <i class="bi bi-filter"></i>
                </button>
              </div>
            </div>
          </form>
          <transition>
            <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
              <div class="row p-fluid p-3 grid">
                <div class="field col-12 col-md-3 mb-4">
                  <!-- <label for="basic">Date de début</label> -->
                  <p-calendar
                    placeholder="Date de début"
                    v-model="dateDeb"
                    locale="fr"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <div class="field col-12 col-md-3">
                  <!-- <label for="basic">Date de fin</label> -->
                  <p-calendar
                    placeholder="Date de fin"
                    class="flex-grow-1"
                    v-model="dateFin"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <!-- <template v-for="(option, index) in filterOptions">
                  <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                    <label for="basic">{{ option.label }}</label>
                    <c-auto-complete
                      v-if="option.url"
                      display="chip"
                      v-model="option.value"
                      chips
                      :option="option"
                      :force-selection="true"
                    />
                    <p-multi-select
                      v-else-if="option.items && option.items.length != 0"
                      v-model="option.value"
                      :options="option.items"
                      :optionLabel="option.key"
                      :placeholder="option.label"
                      display="chip"
                    />
                    <p-input-text
                      v-else
                      v-model="option.value"
                      :placeholder="option.label"
                    />
                  </div>
                  
                </template> -->
                <template v-for="(option, index) in filterOptions">
                  <div
                    class="col-12 col-sm-6 col-md-3 mb-4"
                    v-if="option.visible"
                    :key="index"
                  >
                    <!-- <label for="basic">{{option.label}}</label> -->
                    <!-- <c-auto-complete v-if="option.url" display="chip" v-model="option.value" chips :option="option" :force-selection="true" /> -->
                    <!-- <p-multi-select v-model="option.value" :options="getItemsOf(option.getterName)" :optionLabel="option.labelField" :selectionLimit="3" :placeholder="option.label" optionValue="id" :filter="true" display="chip"/> -->
                    <AsyncMultiSelect
                      v-model="option.value"
                      :multiple="true"
                      :queryUrl="option.queryUrl"
                      :optionLabel="option.labelField"
                      :placeholder="option.label"
                      :emitObject="false"
                      v-if="option.queryUrl"
                      :displayMode="option.displayMode"
                      :searchOptions="[option.labelField]"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="option.value"
                      :options="option.items"
                      v-else
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="option.label"
                      display="chip"
                    />
                  </div>
                </template>
              </div>
            </div>
          </transition>
        </div>
        <template v-if="!hideTitle">
          <p-divider />
          <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
            <div class="my-1 flex-fill">
              <h4>Liste des fiches post-programme</h4>
            </div>
            <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
              <PSplitButton
                label="Exporter"
                :model="exportActions"
                class="p-button-outlined p-button-primary"
              />
            </div>
            <div class="btn-group ms-4">
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                v-tooltip.top="'Affichage mode avatar'"
                @click.prevent="viewMode = 'card'"
                class="btn style_btn btn-md"
                :class="{
                  'btn-success': viewMode == 'card',
                  'btn-light': viewMode == 'table',
                }"
              >
                <i class="bi-grid" />
              </button>
              <button
                @click.prevent="viewMode = 'table'"
                v-tooltip.top="'Affichage mode tableau'"
                class="btn style_btn"
                :class="{
                  'btn-light': viewMode == 'card',
                  'btn-success': viewMode == 'table',
                }"
              >
                <i class="bi-table" />
              </button>
            </div>
          </div>
        </template>
      </template>
      <template v-if="!mxLoading">
        <PDivide v-if="hideTitle" />
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="single"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :sort-by.sync="mxPagination.sortBy"
                :sort-desc.sync="mxPagination.sortDesc"
                responsive="sm"
                sort-icon-left
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(typeProgrammeBeneficie)="{ item }">
                  <PTag
                    :severity="
                      item.typeProgrammeBeneficie != 'EMPLOI_SALARIE' ? 'primary' : 'info'
                    "
                    rounded
                    :value="item.typeProgrammeBeneficie"
                    >{{ item.typeProgrammeBeneficie }}</PTag
                  >
                </template>
                <template #cell(statutEnquete)="{ item }">
                  <PTag
                    :severity="getStatutEnqueteColor(item.statutEnquete)"
                    rounded
                    :value="item.statutEnquete"
                    >{{ item.statutEnquete }}</PTag
                  >
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #row-details="row">
                  <div class="bg- pa-2">
                    <PTabView :activeIndex.sync="activeTab">
                      <PTabPanel header="Fiche Post-Programme">
                        <div v-if="isEnqueteCloture" class="alert alert-warning">
                          Cette enquête a déjà été clôturée. Aucune modification des
                          fiches d'enquête ne sera prise en compte.
                        </div>
                        <QuestionnairePosteStageDetail
                          v-if="activeTab == 0"
                          :demandeurId="row.item.beneficiaireId"
                          :fichePosteProgrammeId="row.item.id"
                        />
                      </PTabPanel>
                    </PTabView>
                  </div>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      v-tooltip.top="'Voir les détails'"
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                    <!-- <a
                      class="btn btn-danger rounded-0 btn-sm me-1"
                      title="Générer PDF"
                      @click.stop.prevent="downloadFiche(row.item)"
                      ><i class="bi bi-printer"></i
                    ></a> -->
                    <!-- <a
                      @click.stop.prevent="modifierFiche(row.item)"
                      v-tooltip.top="`Modifier l'fiche`"
                      target="_blank"
                      class="btn btn-outline-success rounded btn-sm me-1"
                      ><i class="bi bi-pencil"></i
                    ></a> -->
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{
                  (mxPagination.page <= 0 ? 0 : mxPagination.page - 1) *
                  mxPagination.rowsPerPage
                }}
                à
                {{
                  (mxPagination.page <= 0 ? 1 : mxPagination.page) *
                  mxPagination.rowsPerPage
                }}
                de {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import FichePosteProgrammeFormDialog from "./dialog/FichePosteProgrammeFormDialog.vue";
import QuestionnairePosteStageDetail from "../../conseiller/QuestionnairePosteStageDetail.vue";
import AsyncMultiSelect from "../../../../components/common/AsyncMultiSelect.vue";

export default {
  props: {
    agentId: {
      type: Number,
      default: () => null,
    },
    extraQueryArg: {
      type: String,
      default: "",
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    perideMode: {
      type: Boolean,
      default: false,
    },
    isEnqueteCloture: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FichePosteProgrammeFormDialog,
    QuestionnairePosteStageDetail,
    AsyncMultiSelect,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      activeTab: 0,
      showAdvancedFilter: false,
      viewMode: false,
      demandeurType: null,
      demandeurSexe: null,
      stat: {},
      statutEnquete: null,
      statutEnqueteOptions: [
        { value: null, label: "Tous" },
        { value: "GENERE", label: "GENERE" },
        { value: "TERMINE", label: "TERMINE" },
        { value: "RETIRE", label: "RETIRE" },
      ],
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des fiches post-programme",
                subtitle: "Liste des fiches post-programme",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "fichePosteProgrammes/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des fiches post-programme.xlsx",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                apiPath: "fichePosteProgrammes/exportToExcel",
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
          },
        },
      ],
      searchFields: ["beneficiaire.nomPrenom"],
      searchFieldsOptions: [
        {
          label: "Nom(s) et Prénom(s)",
          value: "beneficiaire.nomPrenom",
          visible: true,
        },
      ],
      filterOptions: [
        {
          column: "categorieDemandeur",
          value: "",
          key: "label",
          label: "Type de DE",
          valueKey: "value",
          dropdown: true,
          multiple: false,
          visible: true,
          items: [
            { value: null, label: "Tous" },
            { value: "PPI", label: "PPI" },
            { value: "CI", label: "CI" },
            { value: "INSTRUIT", label: "INSTRUIT" },
          ],
        },
        {
          column: "typeProgrammeBeneficie",
          value: "",
          key: "label",
          label: "Type D'emploi",
          valueKey: "value",
          dropdown: true,
          multiple: false,
          visible: true,
          items: [
            { value: null, label: "Tous" },
            { value: "EMPLOI_SALARIE", label: "SALARIE" },
            { value: "EMPLOI_INDEPENDANT", label: "INDEPENDANT" },
          ],
        },
        {
          column: "programme.id",
          value: "",
          labelField: "nom",
          label: "Programme prescrit",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "programmes",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "remplieParId",
          value: "",
          labelField: "nomPrenom",
          label: "Agent enquêteur",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "agents",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "beneficiaire.departement",
          value: "",
          labelField: "nom",
          label: "Départements",
          queryUrl: "departements",
          displayMode: "classic",
          valueKey: "id",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "beneficiaire.commune",
          value: "",
          labelField: "nom",
          valueKey: "id",
          label: "Communes",
          displayMode: "classic",
          queryUrl: "communes",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "beneficiaire.arrondissement",
          value: "",
          labelField: "nom",
          label: "Arrondissements",
          valueKey: "id",
          queryUrl: "arrondissements",
          displayMode: "classic",
          dropdown: true,
          visible: true,
          multiple: true,
        },
        {
          column: "beneficiaire.ville",
          value: "",
          labelField: "nom",
          label: "Village/Quartier(s)",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "villes",
          dropdown: true,
          visible: true,
          multiple: true,
        },
      ],
      activeDemandeur: {},
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli, // écrase id du demandeur par id azoli
          id: val.id, //remettre Id du demandeur
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    extraQueryArg() {
      this.queryStatistics();
    },
  },
  computed: {
    ...mapGetters({
      pagination: "fichePosteProgramme/pagination",
      demandeurSexeOptions: "setting/demandeurSexeOptions",
      demandeurTypeOptions: "setting/demandeurTypeOptions",
      demandeurVulnerabiliteOptions: "setting/demandeurVulnerabiliteOptions",
    }),
    stats() {
      return Object.values(this.stat);
    },
    statisticsQueryParams() {
      return this.mxBuildQueryParams();
    },

    tableFields() {
      let fields = [
        "#",
        // 'index',
        { key: "beneficiaire.nom", label: "Nom" },
        { key: "beneficiaire.prenom", label: "Prémon" },
        { key: "beneficiaire.sexe", label: "Sexe" },
        { key: "beneficiaire.phone", label: "Phone" },
        { key: "beneficiaire.departement.nom", label: "Département" },
        { key: "beneficiaire.commune.nom", label: "Commune" },
        { key: "typeProgrammeBeneficie", label: "Type Prog." },
        { key: "statutEnquete", label: "Statut" },
        { key: "aid", label: "Agent" },
        { key: "actions", label: "Actions" },
      ];
      return fields;
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchFichePosteProgrammeStatistics: "statistic/fetchFichePosteProgrammeStatistics",
      fetchFichePosteProgrammes: "fichePosteProgramme/fetchFichePosteProgrammes",
      createOrUpdateFichePosteProgramme:
        "fichePosteProgramme/createOrUpdateFichePosteProgramme",
    }),
    getStatutEnqueteColor(statutEnquete) {
      let color = "danger";

      if (statutEnquete == "TERMINE") {
        color = "primary";
      } else if (statutEnquete == "INJOIGNABLE") {
        color = "warning";
      } else if (statutEnquete == "VALIDE") {
        color = "success";
      }

      return color;
    },
    submitQuery() {
      this.mxSubmitQuery();
    },
    showFichePosteProgrammeDialog() {
      this.$refs.ficheDialog.show();
    },
    modifierFiche(fiche) {
      this.$refs.ficheDialog.setEditableItem(fiche);
      this.showFichePosteProgrammeDialog();
    },
    handleFicheSave(fiche) {
      this.createOrUpdateFichePosteProgramme(fiche);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "fichePosteProgramme",
        search: this.search,
        fetcherMethod: "fichePosteProgramme/fetchFichePosteProgrammes",
        dataGetter: "fichePosteProgramme/fichePosteProgrammes",
        paginationGetter: "fichePosteProgramme/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 8,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam("statutEnquete", this.statutEnquete);
          paramPlus = this.mxAddQueryParam(
            "beneficiaire.vulnerabilite",
            this.demandeurVulnerabilite,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam(
            "beneficiaire.sexe",
            this.demandeurSexe,
            paramPlus
          );
          //   paramPlus = this.mxAddQueryParam(
          //     "beneficiaire.sexe",
          //     this.demandeurSexe,
          //     paramPlus
          //   );

          let params = `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });

      this.fetchFichePosteProgrammeStatistics(this.statisticsQueryParams).then((data) => {
        this.stat = data;
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    editDemandeur(demandeur) {
      console.log(demandeur);
      this.activeDemandeur = { ...demandeur };
      this.$router.push({ name: "espace.ce.de.edit" });
    },
    showDemandeur(demandeur) {
      this.activeDemandeur = { ...demandeur };
      this.$refs.detailViewer.show();
    },
    async destroyDemandeur(demandeur) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: "Voulez-vous vraiment supprimer cet élément?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteDemandeur(demandeur);
        },
      });
    },
    updateOrCreateDemandeur(demandeur) {
      this.createOrUpdateDemandeur(demandeur).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 5000,
        });
      });
    },
  },
};
</script>

<style></style>
